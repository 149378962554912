<template>
    <div>
      <template>
        <b-row>
          <b-col md="12">
            <b-card>
                <div class="note-header">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-toggle.sidebar-add-note
                    pill
                  >
                    Add Note
                  </b-button>
                  <search :value="search"
                          placeholder="Find a note"
                          @search="search = $event"/>
                  <div class="icons" v-if="!isMobile()">
                      <svg :class="{ active: grid }" @click="grid = true"  style="
                      color: #38B22D;" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                          <rect x="3" y="3" width="7" height="7"></rect>
                          <rect x="14" y="3" width="7" height="7"></rect>
                          <rect x="14" y="14" width="7" height="7"></rect>
                          <rect x="3" y="14" width="7" height="7"></rect>
                      </svg>
                      <svg :class="{ active: !grid }" @click="grid = false" style="
                      color: #38B22D;" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                          <line x1="8" y1="6" x2="21" y2="6"></line>
                          <line x1="8" y1="12" x2="21" y2="12"></line>
                          <line x1="8" y1="18" x2="21" y2="18"></line>
                          <line x1="3" y1="6" x2="3" y2="6"></line>
                          <line x1="3" y1="12" x2="3" y2="12"></line>
                          <line x1="3" y1="18" x2="3" y2="18"></line>
                      </svg>
                  </div>
                </div>

                <!-- созданные заметки -->
                <notes v-if="MyNotes" :notes="getFilteredNotes" :grid="grid" @remove="removeNote" @refresh="getMyNotes()"/>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <b-sidebar
      id="sidebar-add-note"
      sidebar-class="sidebar-lg modal-responsive-width"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-md-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-1">
            Add Note
          </h5>
          <div>
            <b-button
              variant="outline-primary"
              class="mr-2 min-w-100 px-3"
              id="cancel"
              @click="hide"
              pill
            >
              Cancel
            </b-button>
            <b-button
              pill
              v-if="!note.hashid"
              variant="primary"
              @click="formSubmitted()"
              class="px-3"
            >
              Submit
            </b-button>
  
            <b-button v-else variant="primary" @click="formUpdate()" class="px-3">
              Update
            </b-button>
          </div>
        </div>
        <!-- BODY -->
      <validation-observer ref="NoteForm" tag="form" style="overflow: hidden;">
        <b-form autocomplete="off" id="note-form" class="p-2">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Note Title"
                  rules="required"
                >
                  <label class="required-l" for="name">Note Title</label>
                  <b-form-input
                    id="title"
                    v-model="note.title"
                    name="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Note Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Note Description"
                  rules="required"
                >
                  <label class="required-l" for="description">Note Description</label>
                  <!-- <b-form-textarea
                    id="description"
                    v-model="note.description"
                    name="description"
                    placeholder="Enter Note Description"
                    :state="errors.length > 0 ? false : null"
                    rows="20"
                  /> -->

                  <QuillInput
                    :value.sync="note.description"
                    :toolbar="true"
                    className="post-editor"
                    :mentionList="[]"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Note Priority"
                  rules="required"
                >
                  <label class="required-l" for="priority_level">Note Priority Level</label><br>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="note.priority_level"
                      plain
                      name="priority_level"
                      value="low"
                    >
                      Low
                    </b-form-radio>
                    <b-form-radio
                      v-model="note.priority_level"
                      plain
                      name="priority_level"
                      value="medium"
                    >
                      Medium
                    </b-form-radio>
                    <b-form-radio
                      v-model="note.priority_level"
                      plain
                      name="priorityLevel"
                      value="high"
                    >
                      High
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div class="ml-2">
          <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
            pill
          >
            Cancel
          </b-button>
          <b-button
            pill
            v-if="!note.hashid"
            variant="primary"
            @click="formSubmitted()"
            class="px-3"
          >
            Submit
          </b-button>

          <b-button v-else variant="primary" @click="formUpdate()" class="px-3">
            Update
          </b-button>
        </div>
      </validation-observer>
        
      </template>
    </b-sidebar>
    </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BSidebar
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import notesStoreModule from "@/views/notes/notesStoreModule";

import message from '@/views/notes/Message.vue'
import newNoteCreator from "@/views/notes/NewNoteCreator.vue";
import notes from "@/views/notes/Notes.vue";
import search from "@/views/notes/Search.vue";
import $ from "jquery";
import QuillInput from "@/components/QuillInput.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    BBadge,
    BProgress,
    BProgressBar,
    message,
    newNoteCreator,
    notes,
    search,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    required,
    QuillInput
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const MyNotes = ref([]);
    const USER_APP_STORE_MODULE_NAME = "app-notes";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, notesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    // store
    //   .dispatch("app-notes/my-notes")
    //   .then((response) => {
    //     MyNotes.value = response.data.data;
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 404) {
    //         MyNotes.value = undefined;
    //     }
    //   });
    return {
        MyNotes,
    };
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
      customFields: [],
      MyNotes:[],
      note:{
        hashid: null,
        title: '',
        description: '',
        priority_level: 'low',
        titleEditModeEnabled: false,
        descriptionEditModeEnabled: false
      },
      grid: true,
      search: '',
    };
  },
  created() {
    this.getMyNotes();
    //this.getCustomFields();
  },
  computed: {
    getFilteredNotes() {
      let notesToShow = this.MyNotes;
      if(this.MyNotes.length > 0){
        var notesToSearch = this.search;
        if (!notesToSearch) return sortByPriority(notesToShow);

        notesToSearch = notesToSearch.trim().toLowerCase();
        notesToShow = notesToShow.filter(function (item) {
          if (item.title.toLowerCase().indexOf(notesToSearch) !== -1
              || item.description.toLowerCase().indexOf(notesToSearch) !== -1) {
              return item;
          }
        });
        return sortByPriority(notesToShow);

        // сортировка по приоритету (от высокого к низкому: от красного к синему)
        function sortByPriority(notes) {
          if(notes != null && notes.length > 0){
            return notes.sort((firstElement, secondElement) => secondElement.priorityLevel - firstElement.priorityLevel);
          }
          return notes;
        }
      }else{
        return this.MyNotes;
      }
    },
  },
  methods: {
    getMyNotes(){
      let data = {};
      data.url = "notes";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.MyNotes = res.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Notes fetched successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! unable to fetch your notes",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! unable to fetch your notes',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-okrs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Custom Fields Getting Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.NoteForm.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    isMobile() {
      let isMobileDevice = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      if (isMobileDevice) this.grid = false;
      return isMobileDevice;
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData();
      formData.append("description", self.note.description);
      formData.append("title", self.note.title);
      formData.append("priority_level",self.note.priority_level);

      let data = {};
      data.url = "notes";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.getMyNotes();
          $('#cancel').click();
          $('#note-form').trigger("reset");
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Note Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Note Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Notes Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Notes Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    addNewNote() {
      let {title, description, priorityLevel} = this.note;
      if (title === '') {
        this.message = "Title can't be blank";
        return false;
      }
      this.MyNotes.push({
        title,
        description,
        date: new Date(Date.now()).toLocaleString(),
        priorityLevel
      });
      this.note.id = null;
      this.note.title = "";
      this.note.description = "";
      this.note.priorityLevel = "";
      this.note.titleEditModeEnabled = false;
      this.note.descriptionEditModeEnabled = false;
      this.message = null;
    },

  removeNote(note) {
    var noteId = note.hashid;
    this.$swal({
        title: "Are you sure?",
        text: `You want to delete this note -  ${note.title}, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if(result.value){
          let data = {};
          data.url = "notes/"+noteId;
          this.isBusy = true;
          this.$store
            .dispatch("app/delete", data)
            .then((res) => {
              this.getMyNotes();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Note deleted",
              //     icon: "BellIcon",
              //     variant: "success",
              //     text: res.data.message,
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Note deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            })
            .catch((error) => {
              this.isBusy = false;
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Oops! Notes deletion failed",
              //     icon: "BellIcon",
              //     variant: "danger",
              //     text: error,
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Notes deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            });
        }

      });
    
  },

  }
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
.scroll {
   overflow:auto; white-space: nowrap;width: 100%; padding: 10px;
}
.scroll::-webkit-scrollbar {
    width: 12px;
    height: 6px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #38B22D;
}
</style>