var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notes"},[_vm._l((_vm.notes),function(note){return _c('div',{key:note.id,staticClass:"note",class:{ full: !_vm.grid}},[_c('div',{staticClass:"priority",class:note.priority_level}),_c('div',{staticClass:"text-content"},[_c('div',{staticClass:"note-header"},[_c('p',[_vm._v(_vm._s(note.title)+" "),_c('small',[_vm._v(_vm._s(note.date))])]),_c('b-dropdown',{attrs:{"variant":"link","size":"sm","toggle-class":"text-decoration-none ","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25 text-muted",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-edit-note",modifiers:{"sidebar-edit-note":true}}],staticClass:"btn-sm p-0 text-muted",on:{"click":function($event){_vm.selectedNote=note}}},[_c('feather-icon',{staticClass:"mr-50 text-primary",attrs:{"icon":"EditIcon","size":"13"}}),_c('span',{},[_vm._v("Edit")])],1),_c('b-dropdown-item',{staticClass:"btn-sm p-0",on:{"click":function($event){return _vm.removeNote(note)}}},[_c('feather-icon',{staticClass:"mr-50 text-primary",attrs:{"icon":"TrashIcon","size":"13"}}),_c('span',{},[_vm._v("Delete")])],1)],1)],1),_c('div',{staticClass:"note-body"},[_c('b-card-text',{domProps:{"innerHTML":_vm._s(note.description)}})],1)])])}),_c('b-sidebar',{attrs:{"id":"sidebar-edit-note","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","width":"50%","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Edit Note ")]),_c('div',[_c('b-button',{staticClass:"mr-2 min-w-100 px-3",attrs:{"variant":"outline-primary","id":"editcancel","pill":""},on:{"click":hide}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"px-3",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.formUpdate()}}},[_vm._v(" Update ")])],1)]),_c('validation-observer',{ref:"NoteForm",staticStyle:{"overflow":"hidden"},attrs:{"tag":"form"}},[_c('b-form',{staticClass:"p-2",attrs:{"autocomplete":"off","id":"note-edit-form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Note Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"required-l",attrs:{"for":"name"}},[_vm._v("Note Title")]),_c('b-form-input',{attrs:{"id":"title","name":"title","state":errors.length > 0 ? false : null,"placeholder":"Enter Note Title"},model:{value:(_vm.selectedNote.title),callback:function ($$v) {_vm.$set(_vm.selectedNote, "title", $$v)},expression:"selectedNote.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Note Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"required-l",attrs:{"for":"description"}},[_vm._v("Note Description")]),_c('QuillInput',{attrs:{"value":_vm.selectedNote.description,"toolbar":true,"className":"post-editor","mentionList":[]},on:{"update:value":function($event){return _vm.$set(_vm.selectedNote, "description", $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Note Priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"required-l",attrs:{"for":"priority_level"}},[_vm._v("Note Priority Level")]),_c('br'),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"plain":"","name":"priority_level","value":"low"},model:{value:(_vm.selectedNote.priority_level),callback:function ($$v) {_vm.$set(_vm.selectedNote, "priority_level", $$v)},expression:"selectedNote.priority_level"}},[_vm._v(" Low ")]),_c('b-form-radio',{attrs:{"plain":"","name":"priority_level","value":"medium"},model:{value:(_vm.selectedNote.priority_level),callback:function ($$v) {_vm.$set(_vm.selectedNote, "priority_level", $$v)},expression:"selectedNote.priority_level"}},[_vm._v(" Medium ")]),_c('b-form-radio',{attrs:{"plain":"","name":"priorityLevel","value":"high"},model:{value:(_vm.selectedNote.priority_level),callback:function ($$v) {_vm.$set(_vm.selectedNote, "priority_level", $$v)},expression:"selectedNote.priority_level"}},[_vm._v(" High ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"ml-2"},[_c('b-button',{staticClass:"mr-2 min-w-100 px-3",attrs:{"variant":"outline-primary","pill":""},on:{"click":hide}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"px-3",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.formUpdate()}}},[_vm._v(" Update ")])],1)],1)]}}])}),_c('b-sidebar',{attrs:{"id":"sidebar-view-note","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","width":"50%","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" View Note ")]),_c('div',[_c('b-button',{staticClass:"mr-2 min-w-100 px-3",attrs:{"variant":"outline-primary","id":"editcancel","pill":""},on:{"click":hide}},[_vm._v(" Cancel ")])],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',[_vm._v("Note Title")]),_vm._v(" : "+_vm._s(_vm.selectedNote.title)+" ")]),_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Note Description")]),_vm._v(" : "+_vm._s(_vm.selectedNote.description)+" ")]),_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Note Priority Level")]),_vm._v(" : "+_vm._s(_vm.selectedNote.priority_level)+" ")])],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }